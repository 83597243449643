import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "curated-material---freedom-and-open-source",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material - Freedom and Open Source`}<a parentName="h1" {...{
        "href": "#curated-material---freedom-and-open-source",
        "aria-label": "curated material   freedom and open source permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://opensea.io/blog/guides/non-fungible-tokens/" mdxType="Link">
        <h4 {...{
          "id": "the-nft-token-bible",
          "style": {
            "position": "relative"
          }
        }}>{`The NFT Token Bible`}<a parentName="h4" {...{
            "href": "#the-nft-token-bible",
            "aria-label": "the nft token bible permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Everything you need to know about NFTs, written by Devin Finzer of OpenSea. Also provides a useful history of NFT usage across blockchain games and virtual worlds.`}</p>
      </Link>
      <Link to="https://defold.com/open/" mdxType="Link">
        <h4 {...{
          "id": "defold",
          "style": {
            "position": "relative"
          }
        }}>{`Defold`}<a parentName="h4" {...{
            "href": "#defold",
            "aria-label": "defold permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Defold is an in-house game engine by King. In May 2020 it spun out of the game company and became fully owned by the Defold Foundation. A good case study on transitioning from closed to open source.`}</p>
      </Link>
      <Link to="https://machinations.io/" mdxType="Link">
        <h4 {...{
          "id": "machinations",
          "style": {
            "position": "relative"
          }
        }}>{`Machinations`}<a parentName="h4" {...{
            "href": "#machinations",
            "aria-label": "machinations permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A more complex system design tool, useful if you want to model your systems more accurately. Also usable for game loop design and token engineering.`}</p>
      </Link>
      <Link to="https://gateway.pinata.cloud/ipfs/QmNmJcLc9Me7LERSh5shJmkgEeddFzcn4L1pTeMjT5fXqE/OV_Metaverse_OS_V5.pdf" mdxType="Link">
        <h4 {...{
          "id": "the-open-metaverse-os",
          "style": {
            "position": "relative"
          }
        }}>{`The Open Metaverse OS`}<a parentName="h4" {...{
            "href": "#the-open-metaverse-os",
            "aria-label": "the open metaverse os permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A research paper from Outlier Ventures on building an open metaverse using Web 3.`}</p>
      </Link>
    </List>
    <p>{`Please contribute more research papers with a PR! 📝`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      